import React from "react";
import "./capacity.css";


function Capacity () {
    return (

        <>
        <section className="capacity-section">

            <div className="capacity-image-container">
                <h1><strong>CAPACITY <span>BUILDING</span></strong></h1>
            </div>

            <div className="capacity-texts">
                <h2><strong>CAPACITY BUILDING</strong></h2>
            </div>

            <p>
                Femicide Count - Kenya recognizes the imperative for unified efforts and streamlined approaches in effectively addressing the pressing issue of femicide. Our organization's robust capacity-building initiatives encompass multifaceted strategies, including rigorous research, meticulous case documentation, facilitating referrals for mental health support, and cultivating strategic partnerships. These initiatives are extended to both individuals and institutions, exemplifying our commitment to a collaborative framework that advances the promotion and protection of human rights, particularly for women and children.
            </p>

            <p>
                In addition to our direct support services, Femicide Count - Kenya actively engages in assisting organizations in fortifying their policies. We understand the pivotal role that well-structured policies play in addressing and preventing femicide and other forms of gender-based violence. By offering expertise and guidance, we contribute to the enhancement of organizational frameworks, ensuring they align with the highest standards and practices in safeguarding human rights.
            </p>

            <p>
                As we forge ahead, our shared vision is to coalesce with diverse stakeholders in creating a society where every woman and child not only survives but thrives—liberated from the shackles of violence and discrimination. Together, through collaborative action and steadfast dedication, we aspire to foster enduring change and build a future where the fundamental rights and dignity of all are cherished and protected.
            </p>
        </section>
        </>
    )
}

export default Capacity;