import "./our-team.css";
import React from "react";
import image65 from "../../images/women 65.jpeg";
import image67 from "../../images/women 67.jpg";
// import image15 from "../../images/women 15.jpg";
// import image65 from "../../images/women 65.jpeg";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";


function OurTeam() {
    return (
      <section id="team" className="team">
        <div className="container" data-aos="fade-up">

          <div className="chefs-section-title">
            {/* <h2>Our Team</h2> */}
            <p className="text-center">Our Professional Team</p>
          </div>

          <div className="row">

            {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch"> */}
              {/* <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image67} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4> Dr. Kathomi Gatwiri</h4>
                    <span>Founder</span>
                  </div>
                  <div className="social">
                    <a href="#twitter"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href="#facebook"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="#instagram"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="#linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div> */}
            {/* </div> */}

            {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image15} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Audrey Mugeni</h4>
                    <span>Co-Director</span>
                  </div>
                  <div className="social">
                    <a href="#twitter"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href="#facebook"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="#instagram"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="#linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image67} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Angela Langat</h4>
                    <span>Team Member</span>
                  </div>
                  <div className="social">
                    <a href="" className="twitter"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href=""  className="facebook"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="" target="_blank" rel="noreferrer" className="instagram"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image67} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Dr. Kathomi</h4>
                    <span>Co - Founder</span>
                  </div>
                  <div className="social">
                    <a href="#twitter" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href="#facebook" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="#instagram" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="#linkedin" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image65} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Angela Langat</h4>
                    <span>Member</span>
                  </div>
                  <div className="social">
                    <a href="https://twitter.com/AngelaLangat2" target="_blank" rel="noreferrer"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href="https://www.facebook.com/cher0tuga" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="https://www.instagram.com/chero.tuga/" target="_blank" rel="noreferrer"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="https://www.linkedin.com/in/angela-langat/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image67} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Dr. Joy Kathomi</h4>
                    <span>Founder</span>
                  </div>
                  <div className="social">
                    <a href="#twitter"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href="#facebook"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="#instagram"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="#linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default OurTeam;