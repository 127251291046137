import { useState } from 'react';
import image2 from "../../images/women 2.jpeg";
import image4 from "../../images/women 4.jpeg";
import image5 from "../../images/women 5.jpeg";
import Carousel from 'react-bootstrap/Carousel';
import image16 from "../../images/women 16.jpeg";
// import image67 from "../../images/women 67.jpg";
import image68 from "../../images/women 68.png";
import image69 from "../../images/women 69.png";
import image72 from "../../images/women 72.png";
import image71 from "../../images/women 71.png";
import "../catalogue/catalogue.css";
import { useNavigate } from 'react-router-dom';

function GetStarted() {
    const navigate = useNavigate();

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };

    function handleGetStarted () {
        navigate('/home')
    }
  return (
    <>
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image2} alt='First Slide'/>
                {/* <Carousel.Caption className="text-black py-8">
                    <h3>Map of most affected areas in Kenya</h3>
                    <p>Areas where femicide has occured recently</p>
                </Carousel.Caption> */}
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100 ' src={image4} alt='Second Slide'/>
                {/* <Carousel.Caption className="text-black">
                    <h3>Graph representation of Femicide</h3>
                    <p>A graph of count against age which shows the age of people that have succumbed to femicide and their age too</p>
                </Carousel.Caption> */}
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image5} alt='Third Slide'/>
                {/* <Carousel.Caption className="text-black">
                    <h3>Subjects related to Femicide</h3>
                    <p>Some of the subjects that cause or lead to Femicide</p>
                </Carousel.Caption> */}
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image16} alt='Fourth Slide'/>
                {/* <Carousel.Caption className="text-black">
                    <h3>Cases that were recorded in 2023</h3>
                    <p>The number of cases that were recorded last year</p>
                </Carousel.Caption> */}
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image72} alt='Fifth Slide'/>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image71} alt='Sixth Slide'/>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image68} alt='Seventh Slide'/>
            </Carousel.Item>

            <Carousel.Item>
                <img className='d-block w-100 h-100' src={image69} alt='Eighth Slide'/>
            </Carousel.Item>

            {/* <Carousel.Item>
                <img className='d-block w-100 h-100' src={image67} alt='Nineth Slide'/>
            </Carousel.Item> */}
        </Carousel>

        <div className="p-5">
            <h1 className="text-4xl font-serif font-bold text-center">Femicide in Kenya: A Call to Action</h1>

            <p className="font-serif">
                At femicide Count, we do the work of counting, naming, and putting human stories to women and
                girls whose lives have been ended through violence. In 2023, Femicide Count Kenya recorded 150
                known femicides. This staggering number only represents cases reported in the media - the true count
                is likely much higher. On average, a woman or girl was killed every other day, often at the hands of a
                husband, boyfriend, father, or other family member.
            </p>

            <p>
                These deaths cannot be justified. Selling beans, breaking up with a boyfriend, or burning food are not
                reasons to end a life. Yet trivial motives like these are often cited in cases of femicide. We ask "What
                did she do?" instead of "Why did he kill her?" This tendency to blame victims perpetuates violence
                against women.
            </p>

            <p>
                So far this year, Kenya has seen gruesome murders like that of Starlet Wahu, killed while on a date
                and at her workplace. A 20-year-old girl was dismembered, her body parts scattered by the killer.
                These horrific cases have sparked public outrage, but not enough action.
                Kenya does not adequately protect women and girls. Despite policies meant to promote gender
                equality, femicides continue unabated. After each killing, officials assert that justice will be served, yet
                the violence only escalates.
            </p>

            <p>
                To end femicide, public attitudes must change. The manufacture of misogyny must cease. Women's
                lives matter regardless of profession or perceived transgressions. Men must be empowered to love,
                not hate, the women in their lives.
                The government cannot remain complicit. Kenya is party to international conventions against gender-
                based violence. The president himself has pledged to protect women's lives. These promises are
                hollow when femicide remains rampant. Enforcement and accountability are urgently needed.
            </p>

            <p>
                Femicide Count Kenya condemns these murders unequivocally. Killing women is never justified. As a
                society, we must do better to prevent violence, not excuse it after lives have already been lost. Our
                girls and women deserve safety, dignity and justice. It is time for action.
            </p>

            <div className="report-btn">
                <a href="#get-started" className="report-case-btn" onClick={handleGetStarted}>Get Started</a>
            </div>
        </div>
    </>

  );
}

export default GetStarted;
































// import image2 from "../../images/women 2.jpeg";
// import image4 from "../../images/women 4.jpeg";
// import image5 from "../../images/women 5.jpeg";
// import image16 from "../../images/women 16.jpeg";
// import React, { useState } from 'react';
// import { RxDotFilled } from "react-icons/rx";
// import { BsChevronCompactLeft, BsChevronCompactRight} from "react-icons/bs";

// export default function GetStarted () {
    // const slides = [
    //     {
    //         url : {image2}
    //     },
    //     {
    //         url : {image4}
    //     },
    //     {
    //         url : {image5}
    //     },
    //     {
    //         url : {image16}
    //     },
    //     {
    //         url: 'https://pixabay.com/photos/friendship-day-black-women-portrait-3104635/'
    //     },
    //     {
    //         url: 'https://pixabay.com/photos/sunset-beach-silhouettes-jump-570881/'
    //     },
    //     {
    //         url: 'https://pixabay.com/photos/student-typing-keyboard-text-woman-849822/'
    //     },
    //     {
    //         url: 'https://pixabay.com/photos/friendship-day-black-women-portrait-3104635/'
    //     },
    //     {
    //         url: 'https://pixabay.com/photos/friendship-day-black-women-portrait-3104635/'
    //     },

    // ];

    // const [currentIndex, setCurrentIndex] = useState(0)

    // const prevSlide = () => {
    //     const isFirstSlide = currentIndex === 0 
    //     const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    //     setCurrentIndex(newIndex);
    // };

    // const newSlide = () => {
    //     const isLastSlide = currentIndex === slides.length - 1;
    //     const newIndex = isLastSlide ? 0 : currentIndex - 1;
    //     setCurrentIndex(newIndex);
    // };

    // const goToSlide = (slideIndex) => {
    //     setCurrentIndex(slideIndex);
    // };

    // return (
        // <div className="max-w-[1440px] h-[780px] w-full m-auto py-15 px-4 relative group">
        //     <div style={{backgroundImage: `url(${slides[currentIndex].url})`}} className="w-full h-full rounded-2xl pg-center bg-cover duration-500"></div>
        //     Left Arrow 
        //     <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        //         <BsChevronCompactLeft onClick={prevSlide} size={30} />
        //     </div>

        //     Right Arrow 
        //     <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        //         <BsChevronCompactRight onClick={newSlide} size={30}/>
        //     </div>
        //     <div className="flex top-4 justify-center py-2"> 
        //         {slides.map((slide, slideIndex) => {
        //             <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className="text-2xl cursor-pointer">
        //                 <RxDotFilled />
        //             </div>
        //         })}
        //     </div>
        // </div>

    // )
// }