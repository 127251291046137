import './login.css';
import axios from 'axios';
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { FaCubes, FaAngleLeft } from "react-icons/fa";

function Login () {

    const [values, setValues] = useState({
        username: '',
        email: '', 
        password: ''
    })

    const navigate = useNavigate()
    
    function handleBack () {
        navigate(-1);
    }

    const handleLogin = (event) => {
        event.preventDefault();
        // console.log(values)
        axios.post('http://localhost:8082/tbl_users', values)
        .then(res => {
            if(res.data.Login) {
                alert("Login Successful ♥️")
                navigate('/backendform')
            } else {
                alert("No Record 💔 !!")
            }
            // console.log(res);
        })
        // .catch(err => console.log(err));
    }

    // console.log(values)


    return( 
        <>
            <section className="login-section">

                <button className="back-btn" onClick={handleBack}>
                    <i className="bx bx-back"><FaAngleLeft/></i>
                </button>

                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="yard" style={{borderRadius: "1rem"}}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                                        alt="..." className="img-fluid" style={{borderRadius: "1rem 0 0 1rem"}} />
                                    </div>

                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="yard-body p-4 p-lg-5 text-black">
                                            <form onSubmit={handleLogin}>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    <i className="fas fa-cubes fa-2x me-3" style={{color: "#ed254e"}}><FaCubes/></i>
                                                    <span className="h1 fw-bold mb-0">Logo</span>
                                                </div>

                                                <h5 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>Sign into your account</h5>

                                                <div className="form-outline mb-4">
                                                    <input type="username" name="username" id="username" onChange={e => setValues({...values, username: e.target.value})} className="form-control form-control-lg" required/>
                                                    <label className="form-label" name="username" id="username" type="username" for="form2Example17">Username</label>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <input type="email" name="email" id="email" onChange={e => setValues({...values, email: e.target.value})} className="form-control form-control-lg" required/>
                                                    <label className="form-label" name="email" id="email" type="email" for="form2Example17">Email address</label>
                                                </div>

                                                <div className="form-outline mb-4">
                                                    <input type="password" id="form2Example27" onChange={e => setValues({...values, password: e.target.value})} className="form-control form-control-lg" required/>
                                                    <label className="form-label" for="form2Example27">Password</label>
                                                </div>

                                                <div className="pt-1 mb-4">
                                                    <button className="btn btn-dark btn-lg btn-block" onClick={handleLogin} type="submit">Login</button>
                                                </div>

                                                <a className="small text-muted" href="#!">Forgot password?</a>
                                                <p className="mb-5 pb-lg-2" style={{color: "#393f81", cursor: "pointer"}}>Don't have an account? <a href="#!"
                                                    style={{color: "#393f81"}}>Register here</a></p>
                                                <a href="#!" className="small text-muted">Terms of use.</a>
                                                <a href="#!" className="small text-muted">Privacy policy</a>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;